/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter", sans-serif;
}

.header-logo {
  max-height: 60px;
}

.sticky {
  @apply fixed z-[9999] bg-white bg-opacity-80 transition;
  backdrop-filter: blur(5px);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sticky .navbar-logo {
  @apply py-2;
}

.sticky #navbarToggler span {
  @apply bg-dark;
}

.sticky #navbarCollapse li a {
  @apply text-dark hover:text-primary hover:opacity-100;
}
#navbarCollapse li .ud-menu-scroll.active {
  @apply opacity-70;
}
.sticky #navbarCollapse li .ud-menu-scroll.active {
  @apply text-primary opacity-100;
}
.sticky .loginBtn {
  @apply text-dark hover:text-primary hover:opacity-100;
}

.sticky .signUpBtn {
  @apply bg-primary text-white hover:bg-dark hover:text-white;
}

.navbarTogglerActive > span:nth-child(1) {
  @apply top-[7px] rotate-45 transform;
}
.navbarTogglerActive > span:nth-child(2) {
  @apply opacity-0;
}
.navbarTogglerActive > span:nth-child(3) {
  @apply top-[-8px] rotate-[135deg];
}

.white-icon {
  stroke: white;
  height: 35px;
  width: 35px;
  stroke-width: 1;
}

.sponsor-ruby {
  @apply max-w-[350px];
}

.sponsor-sapphire {
  @apply max-w-[300px];
}

.sponsor-emerald {
  @apply max-w-[300px];
}

.sponsor-community {
  @apply max-w-[200px];
}

.sponsor-podcast {
  @apply max-w-[100px];
  & img {
    @apply max-h-[50px];
  }
}

.sponsor-travel {
  @apply max-w-[100px];
  & img {
    @apply max-h-[50px];
  }
}
